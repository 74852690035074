import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import FieldError from "../common/FieldError";
import {setFormValue, updateFormValue} from "../common/Forms";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import {mergeObjects} from "../common/ObjectUtils";
import SearchInternalListLocal from "../common/SearchInternalListLocal";
import RadioButtonInternalListLocal from "../common/RadioButtonInternalListLocal";
import "./SectionCreateEditComponent.css"
import {toLangOnly} from "../common/LangUtils";

/**
 * To create or edit a Findind Section on a Project.
 *
 * props.listByType : The list of internal list by type
 * props.internalListById : The items per their id
 * props.project: The project
 * props.isEdit: True if we are editing a finding section, false if we are creating one
 * props.initialFindingSection: (optional) the initial finding section. When creating, will start with these values ; when editing, will show those values as a starting point
 * props.updated(project): The action to do when the finding section is created or updated
 * props.cancel(): The action to do when the user clicks "cancel"
 */
function SectionCreateEditComponent(props) {

    const {t, i18n} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState(mergeObjects([{
        sectionId: '',
        subSectionId: '',
        precisionId: '',

        observableId: '',
        observableInspectedId: '',
    }, props.initialFindingSection]))

    function createOrEdit() {

        setFormResult(null)

        if (props.isEdit) {
            // Remove photoAnnotationsByPhotoId from the form
            const formToSend = {...form}
            delete formToSend.photoAnnotationsByPhotoId

            autoRetry(t('common.edit'), () => window.service.projectFindingSectionUpdate(props.project.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.editSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        } else {
            autoRetry(t('common.create'), () => window.service.projectFindingSectionAdd(props.project.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.createSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        }
    }

    return (
        <div className="row edit-section">
            <div className="col">

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                            <strong>{t('project.finding.sectionId')}</strong>
                            <br/>
                            {!props.isEdit && <>
                                <SearchInternalListLocal
                                    listByType={props.listByType}
                                    name="sectionId"
                                    type="PROJECT_SECTION"
                                    selectedId={form.sectionId}
                                    onChangeSelectedItem={(newItem) => {
                                        setForm(form => ({
                                            ...form,
                                            sectionId: newItem ? newItem.id : '',
                                            sectionIdAsDependOn: newItem ? newItem.id : null,
                                        }))
                                    }}
                                />
                                <FieldError formResult={formResult} fieldName="sectionId"/>
                            </>}
                            {props.isEdit && <>
                                {props.internalListById[form.sectionId]?.value[toLangOnly(i18n.language)]}
                            </>}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className="row">
                                <div className="col-12 col-sm-6">
                                    <strong>{t('project.finding.subSectionId')}</strong>
                                    <br/>
                                    {!props.isEdit && <>
                                        <SearchInternalListLocal
                                            listByType={props.listByType}
                                            name="subSectionId"
                                            type="PROJECT_SUBSECTION"
                                            selectedId={form.subSectionId}
                                            dependOnId={form.sectionIdAsDependOn}
                                            onChangeSelectedItem={(newItem) => {
                                                setForm(form => ({
                                                    ...form,
                                                    subSectionId: newItem ? newItem.id : '',
                                                    sectionId: newItem ? newItem.dependOnId : form.sectionId,
                                                }))
                                            }}
                                        />
                                        <FieldError formResult={formResult} fieldName="subSectionId"/>
                                    </>}
                                    {props.isEdit && <>
                                        {props.internalListById[form.subSectionId]?.value[toLangOnly(i18n.language)]}
                                    </>}
                                </div>
                                {props.listByType['PROJECT_PRECISION'].filter(p => p.dependOnId === form.subSectionId).length > 0 &&
                                    <div className="col-12 col-sm-6">
                                        <strong>{t('project.finding.precisionId')}</strong>
                                        <br/>
                                        {!props.isEdit && <>
                                            <SearchInternalListLocal
                                                listByType={props.listByType}
                                                name="precisionId"
                                                type="PROJECT_PRECISION"
                                                selectedId={form.precisionId}
                                                dependOnId={form.subSectionId}
                                                onChangeSelectedItem={(newItem) => {
                                                    setForm(form => ({
                                                        ...form,
                                                        precisionId: newItem ? newItem.id : '',
                                                    }))
                                                }}
                                            />
                                            <FieldError formResult={formResult} fieldName="precisionId"/>
                                        </>}
                                        {props.isEdit && <>
                                            {props.internalListById[form.precisionId]?.value[toLangOnly(i18n.language)]}
                                        </>}
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>
                    {props.isEdit && props.initialFindingSection.findings.length > 0 &&
                        <tr>
                            <td className="bg-warning">{t('project.editFindingSectionWarning')}</td>
                        </tr>
                    }
                    <tr>
                        <td>
                            <strong>{t('project.finding.observableId')}</strong>
                            <br/>
                            <RadioButtonInternalListLocal
                                listByType={props.listByType}
                                name="observableId"
                                type="PROJECT_FINDING_OBSERVABLE"
                                selectedId={form.observableId}
                                onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'observableId', newItem?.id)}
                            />
                            <FieldError formResult={formResult} fieldName="observableId"/>
                        </td>
                    </tr>
                    {form.observableId &&
                        <tr>
                            <td>
                                <strong>{t('project.finding.observableInspectedId')}</strong>
                                <br/>
                                <RadioButtonInternalListLocal
                                    listByType={props.listByType}
                                    name="observableInspectedId"
                                    type="PROJECT_FINDING_OBSERVABLE_INSPECTED"
                                    dependOnId={form.observableId}
                                    selectedId={form.observableInspectedId}
                                    onChangeSelectedItem={(newItem) => {
                                        let newForm = {...form}
                                        newForm['observableInspectedId'] = newItem?.id
                                        newForm['notObservableMotiveId'] = null
                                        setForm(newForm)
                                    }
                                    }
                                />
                                <FieldError formResult={formResult} fieldName="observableInspectedId"/>
                            </td>
                        </tr>
                    }
                    {form.observableInspectedId && props.internalListById[form.observableInspectedId]?.features.includes('notObservable') &&
                        <>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.notObservableMotiveId')}</strong>
                                    <br/>
                                    <RadioButtonInternalListLocal
                                        listByType={props.listByType}
                                        name="notObservableMotiveId"
                                        type="PROJECT_FINDING_NOT_OBSERVABLE_MOTIVE"
                                        selectedId={form.notObservableMotiveId}
                                        onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'notObservableMotiveId', newItem?.id)}
                                    />
                                    <FieldError formResult={formResult} fieldName="notObservableMotiveId"/>
                                </td>
                            </tr>
                        </>
                    }
                    {form.notObservableMotiveId && props.internalListById[form.notObservableMotiveId]?.features.includes('other') &&
                        <>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.notObservableMotiveIdOther')}</strong>
                                    <br/>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="notObservableMotiveIdOther"
                                           value={form.notObservableMotiveIdOther}
                                           onChange={(e) => updateFormValue(e, form, setForm)}
                                    />
                                    <FieldError formResult={formResult} fieldName="notObservableMotiveIdOther"/>
                                </td>
                            </tr>
                        </>
                    }
                    {form.observableInspectedId && props.internalListById[form.observableInspectedId]?.features.includes('operatingState') &&
                        <>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.operatingStateId')}</strong>
                                    <br/>
                                    <RadioButtonInternalListLocal
                                        listByType={props.listByType}
                                        name="operatingStateId"
                                        type="PROJECT_FINDING_OPERATING_STATE"
                                        selectedId={form.operatingStateId}
                                        onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'operatingStateId', newItem?.id)}
                                    />
                                    <FieldError formResult={formResult} fieldName="operatingStateId"/>

                                    {form.observableInspectedId && props.internalListById[form.operatingStateId]?.features.includes('notTested') &&
                                        <div className="deeper">
                                            <strong>{t('project.finding.operatingStateNotTestedMotiveId')}</strong>
                                            <br/>
                                            <SearchInternalListLocal
                                                listByType={props.listByType}
                                                name="operatingStateNotTestedMotiveId"
                                                type="PROJECT_FINDING_OPERATING_STATE_NOT_TESTED_MOTIVE"
                                                selectedId={form.operatingStateNotTestedMotiveId}
                                                onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'operatingStateNotTestedMotiveId', newItem?.id)}
                                            />
                                            <FieldError formResult={formResult}
                                                        fieldName="operatingStateNotTestedMotiveId"/>

                                            {form.operatingStateNotTestedMotiveId && props.internalListById[form.operatingStateNotTestedMotiveId]?.features.includes('other') &&
                                                <div className="deeper">
                                                    <strong>{t('project.finding.operatingStateNotTestedMotiveOther')}</strong>
                                                    <br/>
                                                    <input type="text" className="form-control"
                                                           autoComplete="off"
                                                           name="operatingStateNotTestedMotiveOther"
                                                           value={form.operatingStateNotTestedMotiveOther}
                                                           onChange={(e) => updateFormValue(e, form, setForm)}
                                                    />
                                                    <FieldError formResult={formResult}
                                                                fieldName="operatingStateNotTestedMotiveOther"/>


                                                </div>
                                            }

                                            {form.observableInspectedId && props.internalListById[form.operatingStateNotTestedMotiveId]?.features.includes('impossibleToStart') &&
                                                <div className="deeper">
                                                    <strong>{t('project.finding.operatingStateNotTestedImpossibleStartPrecisionId')}</strong>
                                                    <br/>
                                                    <SearchInternalListLocal
                                                        listByType={props.listByType}
                                                        name="operatingStateNotTestedImpossibleStartPrecisionId"
                                                        type="PROJECT_FINDING_OPERATING_STATE_NOT_TESTED_IMPOSSIBLE_START_PRECISION"
                                                        selectedId={form.operatingStateNotTestedImpossibleStartPrecisionId}
                                                        onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'operatingStateNotTestedImpossibleStartPrecisionId', newItem?.id)}
                                                    />
                                                    <FieldError formResult={formResult}
                                                                fieldName="operatingStateNotTestedImpossibleStartPrecisionId"/>

                                                    {form.operatingStateNotTestedImpossibleStartPrecisionId && props.internalListById[form.operatingStateNotTestedImpossibleStartPrecisionId]?.features.includes('other') &&
                                                        <div className="deeper">
                                                            <strong>{t('project.finding.operatingStateNotTestedImpossibleStartPrecisionOther')}</strong>
                                                            <br/>
                                                            <input type="text" className="form-control"
                                                                   autoComplete="off"
                                                                   name="operatingStateNotTestedImpossibleStartPrecisionOther"
                                                                   value={form.operatingStateNotTestedImpossibleStartPrecisionOther}
                                                                   onChange={(e) => updateFormValue(e, form, setForm)}
                                                            />
                                                            <FieldError formResult={formResult}
                                                                        fieldName="operatingStateNotTestedImpossibleStartPrecisionOther"/>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                </td>
                            </tr>
                        </>
                    }
                    </tbody>
                </table>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => createOrEdit()}>{props.isEdit ? t('common.edit') : t('common.create')}</button>
                <button className="btn btn-outline-primary float-end" type="button"
                        onClick={() => props.cancel && props.cancel()}
                >{t('common.cancel')}</button>
            </div>
        </div>
    )
}

export default SectionCreateEditComponent
