import axios from "axios";
import {toast} from "react-toastify";

export class Service {
    constructor() {
        this.axiosInstance = axios.create({
            baseURL: '',
        })
    }

    getCookie(cookieName) {
        const cookies = document.cookie.split(';')
        for (const cookie of cookies) {
            const cookieParts = cookie.split('=')
            if (cookieParts[0].trim() === cookieName) {
                return cookieParts[1]
            }
        }
        return undefined;
    }

    appDetailsConfigGet() {
        return this.axiosInstance.get('/appDetails/config')
    }

    billCreate(form) {
        return this.axiosInstance.post('/billing/bill', form)
    }

    billFindAll(pageId) {
        return this.axiosInstance.get('/billing/bill/', {
            params: {
                pageId
            }
        })
    }

    billUpdatePaid(billId, paid) {
        return this.axiosInstance.post(`/billing/bill/${billId}/paid`, {
            value: paid
        })
    }

    businessCreate(form) {
        return this.axiosInstance.post('/business/', form)
    }

    businessEdit(slug, form) {
        return this.axiosInstance.post(`/business/${slug}`, form)
    }

    businessLogoUpdate(slug, file) {
        const data = new FormData();
        data.append('file', file);
        return this.axiosInstance.post(`/business/${slug}/logo`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
    }

    businessDisable(slug) {
        return this.axiosInstance.post(`/business/${slug}/disable`)
    }

    businessEnable(slug) {
        return this.axiosInstance.post(`/business/${slug}/enable`)
    }

    businessFindAll(params) {
        return this.axiosInstance.get('/business/', {
            params
        })
    }

    businessGet(slug) {
        return this.axiosInstance.get(`/business/${slug}`)
    }

    businessInspectorFindAll(params) {
        return this.axiosInstance.get('/business/search/inspectors', {
            params
        })
    }

    commonObservationFindAll(sectionId, subSectionId, precisionId, search) {
        return this.axiosInstance.get('/commonObservation/', {
            params: {
                sectionId,
                subSectionId,
                precisionId,
                search
            }
        })
    }

    internalListMapAll() {
        return this.axiosInstance.get(`/internalList/`)
    }

    internalListFindAll(type, dependOnId, search) {
        return this.axiosInstance.get(`/internalList/${type}`, {
            params: {
                dependOnId,
                search,
            }
        })
    }

    projectCreate(form) {
        return this.axiosInstance.post('/project/', form)
    }

    projectAutomaticForm() {
        return this.axiosInstance.get(`/project/automatic`)
    }

    projectGet(id) {
        return this.axiosInstance.get(`/project/${id}`)
    }

    projectFindAll(pageId) {
        return this.axiosInstance.get('/project/', {
            params: {pageId: pageId}
        })
    }

    projectUpdate(projectId, fieldName, fieldValue) {
        return this.axiosInstance.post(`/project/${projectId}/${fieldName}`, {
            value: fieldValue
        })
    }

    projectUpdateAutomaticAddToMany(id, fieldFullName) {
        return this.axiosInstance.post(`/project/${id}/automaticAddToMany/${fieldFullName}`)
    }

    projectUpdateAutomaticRemoveFromMany(id, fieldFullName, index) {
        return this.axiosInstance.delete(`/project/${id}/automaticRemoveFromMany/${fieldFullName}`, {
            params: {
                index
            }
        })
    }

    projectUpdateAutomaticText(projectId, fieldName, fieldValue) {
        return this.axiosInstance.post(`/project/${projectId}/automaticText/`, {
            fieldName: fieldName,
            value: fieldValue
        })
    }

    projectUpdateAutomaticIdAndPrecisionId(projectId, fieldName, fieldValue) {
        return this.axiosInstance.post(`/project/${projectId}/automaticIdAndPrecisionId/${fieldName}`, {
            value: fieldValue
        })
    }

    projectApplicantAdd(projectId, form) {
        return this.axiosInstance.post(`/project/${projectId}/applicants`, form)
    }

    projectApplicantUpdate(projectId, applicantId, form) {
        return this.axiosInstance.post(`/project/${projectId}/applicants/${applicantId}`, form)
    }

    projectApplicantDelete(projectId, applicantId) {
        return this.axiosInstance.delete(`/project/${projectId}/applicants/${applicantId}`)
    }

    projectDocumentAdd(projectId, documentDisplayId, file) {
        const data = new FormData();
        data.append('file', file);
        return this.axiosInstance.post(`/project/${projectId}/documents/${documentDisplayId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
    }

    projectDocumentUpdateNoDocumentJustification(projectId, documentDisplayId, newValue) {
        return this.axiosInstance.post(`/project/${projectId}/documents/${documentDisplayId}/noDocumentJustification`, {value: newValue})
    }

    projectDocumentDelete(projectId, documentDisplayId, projectDocumentId) {
        return this.axiosInstance.delete(`/project/${projectId}/documents/${documentDisplayId}/${projectDocumentId}`)
    }

    projectFinalizeReport(projectId) {
        return this.axiosInstance.post(`/project/${projectId}/finalizeReport`)
    }

    projectFindingSectionAdd(projectId, form) {
        return this.axiosInstance.post(`/project/${projectId}/findingSections`, form)
    }

    projectFindingSectionUpdate(projectId, form) {
        return this.axiosInstance.put(`/project/${projectId}/findingSections`, form)
    }

    projectFindingSectionDelete(projectId, form) {
        return this.axiosInstance.delete(`/project/${projectId}/findingSections`, {
            data: form
        })
    }

    projectFindingAdd(projectId, form) {
        return this.axiosInstance.post(`/project/${projectId}/findings`, form)
    }

    projectFindingGet(projectId, findingId) {
        return this.axiosInstance.get(`/project/${projectId}/findings/${findingId}`)
    }

    projectFindingUpdate(projectId, findingId, form) {
        return this.axiosInstance.post(`/project/${projectId}/findings/${findingId}`, form)
    }

    projectFindingDelete(projectId, findingId) {
        return this.axiosInstance.delete(`/project/${projectId}/findings/${findingId}`)
    }

    projectFindingPhotoAnnotationUpdate(projectId, findingId, photoId, annotations) {
        return this.axiosInstance.post(`/project/${projectId}/findings/${findingId}/photoAnnotationsByPhotoId/${photoId}`, {
            photoAnnotations: annotations
        })
    }

    projectOwnerAdd(projectId, form) {
        return this.axiosInstance.post(`/project/${projectId}/owners`, form)
    }

    projectOwnerUpdate(projectId, ownerId, form) {
        return this.axiosInstance.post(`/project/${projectId}/owners/${ownerId}`, form)
    }

    projectOwnerDelete(projectId, ownerId) {
        return this.axiosInstance.delete(`/project/${projectId}/owners/${ownerId}`)
    }

    projectPresentPersonAdd(projectId, form) {
        return this.axiosInstance.post(`/project/${projectId}/presentPersons`, form)
    }

    projectPresentPersonUpdate(projectId, presentPersonId, form) {
        return this.axiosInstance.post(`/project/${projectId}/presentPersons/${presentPersonId}`, form)
    }

    projectPresentPersonDelete(projectId, presentPersonId) {
        return this.axiosInstance.delete(`/project/${projectId}/presentPersons/${presentPersonId}`)
    }

    projectPhotoAdd(projectId, file) {
        const data = new FormData();
        data.append('file', file);
        return this.axiosInstance.post(`/project/${projectId}/photos`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
    }

    projectPhotoDelete(projectId, photoId) {
        return this.axiosInstance.delete(`/project/${projectId}/photos/${photoId}`)
    }

    projectBillingFindAllByState(pageId, onlyCreated, notBilled, partiallyBilled, billed) {
        return this.axiosInstance.get(`/billing/projectBilling/`, {
            params: {
                pageId,
                onlyCreated,
                notBilled,
                partiallyBilled,
                billed,
            }
        })
    }

    projectSendReport(projectId) {
        return this.axiosInstance.post(`/project/${projectId}/sendReport`)
    }

    registerCreate(form) {
        return this.axiosInstance.post('/register/', form)
    }

    registerMostRecents() {
        return this.axiosInstance.get('/register/mostRecents')
    }

    requestAction(requestId, approve) {
        return this.axiosInstance.post(`/request/${requestId}`, {
            approve: approve
        })
    }

    requestDelete(requestId) {
        return this.axiosInstance.delete(`/request/${requestId}`)
    }

    requesFindAllMine(pageId) {
        return this.axiosInstance.get('/request/mine', {
            params: {pageId: pageId}
        })
    }

    requesFindAllToApprove(pageId) {
        return this.axiosInstance.get('/request/toApprove', {
            params: {pageId: pageId}
        })
    }

    userCreateToken(form) {
        return this.axiosInstance.post('/user/createToken', form)
    }

    userChangePassword(form) {
        return this.axiosInstance.post('/user/changePassword', form)
    }

    userChangeLang(lang) {
        return this.axiosInstance.get('/user/changeLang', {params: {lang: lang}})
    }

    userGetMe() {
        return this.axiosInstance.get('/user/me')
    }

    userGetEntitlements(businessSlug) {
        return this.axiosInstance.get('/user/entitlements', {
                params: {
                    businessSlug
                }
            }
        )
    }

    userFindAll(pageId, search, isAdmin, isInspector, isBusinessAdministrator, isBusinessEmployee, isWithoutBusiness) {
        return this.axiosInstance.get('/user/', {
            params: {
                pageId,
                search,
                isAdmin,
                isInspector,
                isBusinessAdministrator,
                isBusinessEmployee,
                isWithoutBusiness,
            }
        })
    }

    userLogIn(form) {
        return this.axiosInstance.post('/user/login', form)
    }

    userLogout() {
        return this.axiosInstance.post('/user/logout')
    }

    userInspectorProfileUpdateFullName(newFullName) {
        return this.axiosInstance.post('/user/inspectorProfile/fullName', {
            fullName: newFullName
        })
    }

    userInspectorProfileUpdateRbqLicenseNumber(newRbqLicenseNumber) {
        return this.axiosInstance.post('/user/inspectorProfile/rbqLicenseNumber', {
            value: newRbqLicenseNumber
        })
    }

    userInspectorProfileUpdateInsuranceName(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/name', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceCertificateNumber(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/certificateNumber', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceProfessionalCoveragePolicyNumber(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/professionalCoveragePolicyNumber', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceCivilCoveragePolicyNumber(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/civilCoveragePolicyNumber', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceProfessionalCoverageByDisaster(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/professionalCoverageByDisaster', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceProfessionalCoverageByPeriod(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/professionalCoverageByPeriod', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceCivilCoverageByDisaster(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/civilCoverageByDisaster', {
            value: value
        })
    }

    userInspectorProfileUpdateInsuranceCivilCoverageByPeriod(value) {
        return this.axiosInstance.post('/user/inspectorProfile/insurance/civilCoverageByPeriod', {
            value: value
        })
    }

    userProfileSignatureUpdate(file) {
        const data = new FormData();
        data.append('file', file);
        return this.axiosInstance.post(`/user/profile/signature`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
    }

    userProfileSignatureDelete() {
        return this.axiosInstance.delete(`/user/profile/signature`)
    }

}

export function autoRetry(context, promiseProvider, delayInSec, silent = false) {

    return new Promise((resolve) => {

        let execute = () => {
            promiseProvider()
                .then((response) => resolve(response))
                .catch((e) => {
                    if (e.response && e.response.status === 403) {
                        if (!silent) {
                            toast.warn(`[${context}] Problem getting the response. ${e}`)
                        }
                    } else {
                        if (!silent) {
                            toast.warn(`[${context}] Problem getting the response. Will retry in ${delayInSec} seconds. ${e}`)
                        }
                        setTimeout(execute, delayInSec * 1000)
                    }
                })
        }

        execute()

    })

}

export function failuresToToast(context, promiseProvider, showSuccess = true) {

    return new Promise((resolve, reject) => {

        let execute = () => {
            promiseProvider()
                .then((response) => {

                    if (response.data.success === undefined || response.data.success) {
                        if (showSuccess) {
                            toast.success(`[${context}] Done`)
                        }
                        resolve(response)
                    } else {
                        console.log('ERROR', context, response.data)
                        let errors = []
                        if (response.data.error) {
                            const error = response.data.error
                            errors.push(`${error.timestamp} - ${error.uniqueId} - ${error.message}`)
                        }
                        if (response.data.globalErrors) {
                            for (const globalError of response.data.globalErrors) {
                                errors.push(globalError)
                            }
                        }
                        if (response.data.validationErrorsByField) {
                            for (const fieldName in response.data.validationErrorsByField) {
                                for (const error of response.data.validationErrorsByField[fieldName]) {
                                    errors.push(`(${fieldName}) ${error}`)
                                }
                            }
                        }

                        for (const error of errors) {
                            toast.error(`[${context}] ${error}`, {
                                autoClose: 20000,
                            })
                        }

                        reject(response)
                    }

                })
                .catch((e) => {
                    toast.error(`[${context}] Problem getting the response. ${e}`, {
                        autoClose: 20000,
                    })
                })
        }

        execute()

    })

}
