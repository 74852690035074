import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import FieldError from "../common/FieldError";
import SearchInternalList from "../common/SearchInternalList";
import {setFormValue, toggleCheckbox, updateFormValue} from "../common/Forms";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import {mergeObjects} from "../common/ObjectUtils";
import AddressAutocomplete from "../common/AddressAutocomplete";

/**
 * To create or edit an Applicant on a Project.
 *
 * props.googleMapsApiReady: True if the Google Maps API is ready
 * props.project: The project
 * props.initialApplicant: (optional) the initial Applicant. When creating, will start with these values ; when editing, will show those values as a starting point
 * props.updated(project): The action to do when the Applicant is created or updated
 * props.cancel(): The action to do when the user clicks "cancel"
 */
function ApplicantCreateEditComponent(props) {

    const {t} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState(mergeObjects([{
        id: null,
        typeId: '',

        firstName: '',
        lastName: '',

        corporationName: '',
        representativeFirstName: '',
        representativeLastName: '',

        currentAddress: {
            full: ''
        },
        phoneNumber: '',
        email: '',

        presentDuringInspection: false,
    }, props.initialApplicant]))

    const [typeFeatures, setTypeFeatures] = useState([])

    function createOrEdit() {

        setFormResult(null)

        if (form.id) {
            autoRetry(t('common.edit'), () => window.service.projectApplicantUpdate(props.project.id, form.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.editSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        } else {
            autoRetry(t('common.create'), () => window.service.projectApplicantAdd(props.project.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.createSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        }
    }

    function changeSelectedType(newItem) {
        setFormValue(form, setForm, 'typeId', newItem?.id)
        setTypeFeatures(newItem?.features || [])
    }

    return (
        <div className="row edit-section">
            <div className="col">

                <h2>{t('project.applicant.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <table className="table">
                    <tbody>
                    <tr>
                        <td>
                            <strong>{t('project.applicant.typeId')}</strong>
                            <br/>
                            <SearchInternalList
                                name="typeId"
                                type="PROJECT_APPLICANT_TYPE"
                                selectedId={form.typeId}
                                onChangeSelectedItem={changeSelectedType}
                            />
                            <FieldError formResult={formResult} fieldName="typeId"/>
                        </td>
                    </tr>
                    {typeFeatures.includes('individual') && <>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.firstName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="firstName"
                                       value={form.firstName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="firstName"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.lastName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="lastName"
                                       value={form.lastName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="lastName"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.representativeFirstName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="representativeFirstName"
                                       value={form.representativeFirstName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="representativeFirstName"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.representativeLastName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="representativeLastName"
                                       value={form.representativeLastName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="representativeLastName"/>
                            </td>
                        </tr>
                    </>
                    }
                    {typeFeatures.includes('corporation') && <>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.corporationName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="corporationName"
                                       value={form.corporationName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="corporationName"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.representativeFirstName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="representativeFirstName"
                                       value={form.representativeFirstName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="representativeFirstName"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>{t('project.applicant.representativeLastName')}</strong>
                                <br/>
                                <input type="text" className="form-control"
                                       autoComplete="off"
                                       name="representativeLastName"
                                       value={form.representativeLastName}
                                       onChange={(e) => updateFormValue(e, form, setForm)}
                                />
                                <FieldError formResult={formResult} fieldName="representativeLastName"/>
                            </td>
                        </tr>
                    </>
                    }
                    <tr>
                        <td>
                            <strong>{t('project.applicant.currentAddress')}</strong>
                            <br/>
                            {props.googleMapsApiReady && <AddressAutocomplete
                                address={form.currentAddress}
                                onChange={(e) => setFormValue(form, setForm, 'currentAddress', e)}
                            />
                            }
                            <FieldError formResult={formResult} fieldName="currentAddress"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.applicant.phoneNumber')}</strong>
                            <br/>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="phoneNumber"
                                   value={form.phoneNumber}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                            />
                            <FieldError formResult={formResult} fieldName="phoneNumber"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.applicant.email')}</strong>
                            <br/>
                            <input type="text" className="form-control"
                                   autoComplete="off"
                                   name="email"
                                   value={form.email}
                                   onChange={(e) => updateFormValue(e, form, setForm)}
                            />
                            <FieldError formResult={formResult} fieldName="email"/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{t('project.applicant.presentDuringInspection')}</strong>
                            <br/>
                            <input type="checkbox"
                                   checked={form.presentDuringInspection}
                                   onChange={() => toggleCheckbox(form, setForm, 'presentDuringInspection')}
                            />
                            <FieldError formResult={formResult} fieldName="presentDuringInspection"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => createOrEdit()}>{form.id ? t('common.edit') : t('common.create')}</button>
                <button className="btn btn-outline-primary float-end" type="button"
                        onClick={() => props.cancel && props.cancel()}
                >{t('common.cancel')}</button>
            </div>
        </div>
    )
}

export default ApplicantCreateEditComponent
