import React, {useState} from "react";
import {useTranslation} from "react-i18next";

/**
 * Display and select photos.
 * - projectId : Project id
 * - allPhotos : All the photos
 * - onDelete(photoId) : (optional) The action to do when the delete button besides a photo is clicked
 */
function PhotoViewer(props) {

    const {t} = useTranslation()

    const [viewImage, setViewImage] = useState(null)

    const [allPhotosFilterOrphan, setAllPhotosFilterOrphan] = useState(true)
    const [allPhotosFilterNotOrphan, setAllPhotosFilterNotOrphan] = useState(true)

    const filteredPhotos = []
    props.allPhotos && props.allPhotos.forEach(photo => {
            // Hide by filters
            if (photo.orphan && !allPhotosFilterOrphan) {
                return
            }
            if (!photo.orphan && !allPhotosFilterNotOrphan) {
                return
            }

            filteredPhotos.push(photo)
        }
    )

    function doDeletePhoto(photoId) {
        props.onDelete(photoId)
    }

    return (<>
        <div className="scrollable-section">
            <div className="scrollable-section-inside">

                <div className="row">
                    <div className="col">
                        <div className="form-check form-switch">
                            <input id="allPhotosFilterOrphan"
                                   className="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   checked={allPhotosFilterOrphan}
                                   onChange={() => setAllPhotosFilterOrphan(!allPhotosFilterOrphan)}
                            />
                            <label className="form-check-label"
                                   htmlFor="allPhotosFilterOrphan">{t('photo.filterOrphan')}</label>
                        </div>
                        <div className="form-check form-switch">
                            <input id="allPhotosFilterNotOrphan"
                                   className="form-check-input"
                                   type="checkbox"
                                   role="switch"
                                   checked={allPhotosFilterNotOrphan}
                                   onChange={() => setAllPhotosFilterNotOrphan(!allPhotosFilterNotOrphan)}
                            />
                            <label className="form-check-label"
                                   htmlFor="allPhotosFilterNotOrphan">{t('photo.filterNotOrphan')}</label>
                        </div>
                    </div>
                </div>

                <div className="row">

                    {filteredPhotos.map((photo, photoIdx) => (
                        <div key={photo.id}
                             className="card col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                            <div>
                                {props.onDelete &&
                                    <button
                                        width="20px"
                                        type="button" className="btn btn-danger btn-sm float-end"
                                        onClick={() => doDeletePhoto(photo.id)}
                                    >
                                        <i className="bi bi-trash"></i>
                                    </button>
                                }

                                {photo.orphan &&
                                    <span className="badge bg-info float-end">{t('photo.orphan')}</span>
                                }

                                <img
                                    src={`/project/${props.projectId}/photos/${photo.id}?maxSide=1000`}
                                    className="card-img-top"
                                />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{photo.name}</h5>
                                <p className="card-text"></p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    </>)

}

export default PhotoViewer
