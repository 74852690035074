import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import GlobalErrorsAndWarnings from "../common/GlobalErrorsAndWarnings";
import FieldError from "../common/FieldError";
import {setFormValue, updateFormValue, updateFormValues} from "../common/Forms";
import StringList from "../common/StringList";
import {autoRetry} from "../service";
import {toast} from "react-toastify";
import PhotoSelector from "../common/PhotoSelector";
import {mergeObjects} from "../common/ObjectUtils";
import SearchInternalListLocal from "../common/SearchInternalListLocal";
import ImageViewer from "../common/ImageViewer";
import TypeAhead from "../common/TypeAhead";
import {toLangOnly} from "../common/LangUtils";
import "./FindingCreateEditComponent.css"

/**
 * To create or edit a Finding on a Project.
 *
 * props.findingSection: The section of the finding
 * props.listByType: The list of internal list by type
 * props.internalListById: The items per their id
 * props.project: The project
 * props.findingId: (optional) Finding id
 * props.initialFinding: (optional) the initial finding. When creating, will start with these values ; when editing, will show those values as a starting point
 * props.updated(project): The action to do when the finding is created or updated
 * props.cancel(): The action to do when the user clicks "cancel"
 */
function FindingCreateEditComponent(props) {

    const {t, i18n} = useTranslation()

    const [formResult, setFormResult] = useState({})
    const [form, setForm] = useState(mergeObjects([{
        id: null,

        typeId: '',

        photoIds: [],
        observations: [''],
        location: '',
        comments: [''],
        recommendations: [''],

        priorityId: '',
    }, props.initialFinding, {
        sectionId: props.findingSection.sectionId,
        subSectionId: props.findingSection.subSectionId,
        precisionId: props.findingSection.precisionId,
    }
    ]))

    const internalListById = props.internalListById
    const findingSection = props.findingSection

    const [showPhotoSelector, setShowPhotoSelector] = useState(false)
    const [viewImage, setViewImage] = useState(null)
    const [forceRefresh, setForceRefresh] = useState({})

    const [observationToAdd, setObservationToAdd] = useState()
    const [observationToAddOnlyText, setObservationToAddOnlyText] = useState()

    const annotationsFindingId = props.findingId ? props.findingId : 'NEXT'

    const fixedPriority = form.typeId && (internalListById[form.typeId]?.features.includes('highPriority') || internalListById[form.typeId]?.features.includes('nonePriority'))

    // Ensure there is always at least as many comments and recommendations as observations
    useEffect(() => {
        if (form.observations.length > form.comments.length) {
            setForm(form => ({
                ...form,
                comments: form.comments.concat(Array(form.observations.length - form.comments.length).fill(''))
            }))
        }
        if (form.observations.length > form.recommendations.length) {
            setForm(form => ({
                ...form,
                recommendations: form.recommendations.concat(Array(form.observations.length - form.recommendations.length).fill(''))
            }))
        }
    }, [form.observations])

    function addPhotos(newSelectedIds) {

        setShowPhotoSelector(false)

        const photoIds = []
        form.photoIds.forEach(p => photoIds.push(p))
        newSelectedIds.forEach(p => photoIds.push(p))

        setForm(form => ({
            ...form,
            photoIds: photoIds
        }))
    }

    function removePhoto(idToRemove) {
        const photoIds = [...form.photoIds]
        const indexToRemove = photoIds.findIndex(id => id === idToRemove)
        photoIds.splice(indexToRemove, 1)

        setForm(form => ({
            ...form,
            photoIds: photoIds
        }))
    }

    function createOrEdit() {

        setFormResult(null)

        if (form.id) {
            // Remove photoAnnotationsByPhotoId from the form
            const formToSend = {...form}
            delete formToSend.photoAnnotationsByPhotoId

            autoRetry(t('common.edit'), () => window.service.projectFindingUpdate(props.project.id, form.id, formToSend), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.editSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        } else {
            autoRetry(t('common.create'), () => window.service.projectFindingAdd(props.project.id, form), 5).then(response => {
                setFormResult(response.data)
                if (response.data.success) {
                    toast.success(t('common.createSuccess'))

                    props.updated && props.updated(response.data.item)
                }
            })
        }
    }

    function changeSelectedPhoto(photoIdIdx, findingId) {
        if (photoIdIdx < 0) {
            photoIdIdx = form.photoIds.length - 1
        }
        if (photoIdIdx >= form.photoIds.length) {
            photoIdIdx = 0
        }

        const photoId = form.photoIds[photoIdIdx]
        setViewImage({
            photoId: photoId,
            findingId: findingId,
            edit: true,
            previous: () => changeSelectedPhoto(photoIdIdx - 1, findingId),
            next: () => changeSelectedPhoto(photoIdIdx + 1, findingId),
            forceRefresh: () => setForceRefresh(prev => ({
                ...prev,
                [photoId]: new Date().getTime()
            })),
        })
    }

    function observationSearchOrCreateSelected(newItem) {
        setObservationToAdd(newItem)
    }

    function observationSearchOrCreateSearchValue(newSearchValue) {
        if (!newSearchValue) {
            setObservationToAddOnlyText(null)
            return
        }
        setObservationToAddOnlyText(newSearchValue)
    }

    function observationSearchOrCreateAdd() {

        // Pick the observationToAdd if the text in it is the same as observationToAddOnlyText ; otherwise, create a new one with only that observation
        let observation = observationToAdd
        if (!observation || observation.observationByLanguage[toLangOnly(i18n.language)] !== observationToAddOnlyText) {
            observation = {
                observationByLanguage: {
                    [toLangOnly(i18n.language)]: observationToAddOnlyText
                },
                commentByLanguage: {
                    [toLangOnly(i18n.language)]: ''
                },
                recommendationByLanguage: {
                    [toLangOnly(i18n.language)]: ''
                },
            }
        }

        const newForm = {...form}

        // Find the index of the first empty observation (or the next id at the end)
        let observationIdx = newForm.observations.findIndex(o => !o)
        if (observationIdx === -1) {
            observationIdx = newForm.observations.length
        }

        // Create any missing observations, comments and recommendations
        while (newForm.observations.length <= observationIdx) {
            newForm.observations.push('')
        }
        while (newForm.comments.length <= observationIdx) {
            newForm.comments.push('')
        }
        while (newForm.recommendations.length <= observationIdx) {
            newForm.recommendations.push('')
        }

        // Set the observation, comment and recommendation for this index
        newForm.observations[observationIdx] = observation.observationByLanguage[toLangOnly(i18n.language)]
        newForm.comments[observationIdx] = observation.commentByLanguage[toLangOnly(i18n.language)]
        newForm.recommendations[observationIdx] = observation.recommendationByLanguage[toLangOnly(i18n.language)]

        setForm(newForm)
    }

    return (
        <div className="row edit-section">
            <div className="col">

                <ImageViewer
                    projectId={props.project.id}
                    photoId={viewImage?.photoId}
                    findingId={viewImage?.findingId}
                    edit={viewImage?.edit}
                    onClose={changed => {
                        setViewImage(null)
                        // Refresh the image if changed
                        if (changed && viewImage.forceRefresh) {
                            viewImage.forceRefresh()
                        }
                    }}
                    onPrevious={viewImage?.previous}
                    onNext={viewImage?.next}
                />

                <h2>{t('project.finding.title')}</h2>

                <GlobalErrorsAndWarnings formResult={formResult}/>

                <table className="table">
                    <tbody>
                    {findingSection.observableInspectedId && !internalListById[findingSection.observableInspectedId]?.features.includes('notObservable') &&
                        <>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.typeId')}</strong>
                                    <br/>
                                    <SearchInternalListLocal
                                        listByType={props.listByType}
                                        name="typeId"
                                        type="PROJECT_FINDING_TYPE"
                                        selectedId={form.typeId}
                                        onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'typeId', newItem?.id)}
                                    />
                                    <FieldError formResult={formResult} fieldName="typeId"/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.location')}</strong>
                                    <br/>
                                    <input type="text" className="form-control"
                                           autoComplete="off"
                                           name="location"
                                           value={form.location}
                                           onChange={(e) => updateFormValue(e, form, setForm)}
                                    />
                                    <FieldError formResult={formResult} fieldName="location"/>
                                </td>
                            </tr>
                        </>
                    }
                    {findingSection.observableInspectedId && !internalListById[findingSection.observableInspectedId]?.features.includes('notObservable') &&
                        <>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.observations')}</strong>
                                    <br/>
                                    <p>{t('project.finding.observationSearchOrCreate')}</p>
                                    <TypeAhead
                                        name="observationSearchOrCreate"
                                        onChangeSelectedItem={observationSearchOrCreateSelected}
                                        onChangeSearchValue={observationSearchOrCreateSearchValue}
                                        makeSearch={(searchValue) => window.service.commonObservationFindAll(form.sectionId, form.subSectionId, form.precisionId, searchValue)}
                                        initialSearch={true}
                                        selectFirstInitially={false}
                                        toId={o => o ? o.id : null}
                                        toDisplay={o => o.observationByLanguage[toLangOnly(i18n.language)]}
                                        toFieldSearch={o => o.observationByLanguage[toLangOnly(i18n.language)]}
                                        forceRefresh={[form.sectionId, form.subSectionId, form.precisionId]}
                                    />
                                    <button className="btn btn-success" type="button"
                                            onClick={observationSearchOrCreateAdd}
                                            disabled={!observationToAddOnlyText}
                                    >
                                        {t('common.add')}
                                    </button>
                                    <hr/>
                                    <StringList
                                        values={form.observations}
                                        onChange={(newList) => updateFormValues('observations', newList, form, setForm)}
                                        rows="3"
                                        prefixRowLabel="project.finding.observationPrefix"
                                        labelOnSeparateRow={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="observations"/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.comments')}</strong>
                                    <br/>
                                    <StringList
                                        values={form.comments}
                                        onChange={newList => setFormValue(form, setForm, 'comments', newList)}
                                        maxItems={2}
                                        rows={3}
                                        prefixRowLabel="project.finding.commentPrefix"
                                        labelOnSeparateRow={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="comments"/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>{t('project.finding.recommendations')}</strong>
                                    <br/>
                                    <StringList
                                        values={form.recommendations}
                                        onChange={newList => setFormValue(form, setForm, 'recommendations', newList)}
                                        rows={3}
                                        prefixRowLabel="project.finding.recommendationPrefix"
                                        labelOnSeparateRow={true}
                                    />
                                    <FieldError formResult={formResult} fieldName="recommendations"/>
                                </td>
                            </tr>
                            {!fixedPriority &&
                                <tr>
                                    <td>
                                        <strong>{t('project.finding.priorityId')}</strong>
                                        <br/>
                                        <SearchInternalListLocal
                                            listByType={props.listByType}
                                            name="priorityId"
                                            type="PROJECT_PRIORITY"
                                            selectedId={form.priorityId}
                                            onChangeSelectedItem={(newItem) => setFormValue(form, setForm, 'priorityId', newItem?.id)}
                                        />
                                        <FieldError formResult={formResult} fieldName="priorityId"/>
                                    </td>
                                </tr>
                            }
                        </>
                    }
                    <tr>
                        <td>
                            <strong>{t('project.finding.photoIds')}</strong>
                            <br/>

                            <button className={'btn btn-success'} type="button"
                                    onClick={() => setShowPhotoSelector(true)}>
                                +
                            </button>
                            {showPhotoSelector && <PhotoSelector
                                projectId={props.project.id}
                                findingId={annotationsFindingId}
                                allPhotos={props.project.photos}
                                selectedIds={form.photoIds}
                                onAction={addPhotos}
                            />}

                            <div className="row">
                                {form.photoIds.map((photoId, photoIdIdx) => (
                                    <div key={photoId}
                                         className="card col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
                                        <div>
                                            <button className="btn btn-danger float-end" type="button"
                                                    onClick={() => removePhoto(photoId)}>
                                                X
                                            </button>
                                            <img
                                                src={`/project/${props.project.id}/photos/${photoId}?maxSide=1000&findingId=${annotationsFindingId}&forceRefresh=${forceRefresh[photoId]}`}
                                                className="card-img-top"
                                                onClick={() => changeSelectedPhoto(photoIdIdx, annotationsFindingId)}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <FieldError formResult={formResult} fieldName="form.photoIds"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <button className="btn btn-success float-end" type="button"
                        onClick={() => createOrEdit()}>{form.id ? t('common.edit') : t('common.create')}</button>
                <button className="btn btn-outline-primary float-end" type="button"
                        onClick={() => props.cancel && props.cancel()}
                >{t('common.cancel')}</button>
            </div>
        </div>
    )
}

export default FindingCreateEditComponent
