import React from "react"
import {NavLink, useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {autoRetry} from "../service"
import './Header.css'
import logo from './logo.png'
import {toLangOnly} from "../common/LangUtils";

/**
 * The header with menu.
 *
 * props.userEntitlements: The users entitlements
 * props.onLogout(): The action to do when the user logged out
 */
function Header(props) {

    const {t, i18n} = useTranslation()

    const userEntitlements = props.userEntitlements ? props.userEntitlements : {}
    const navigate = useNavigate()

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng)
        window.service.userChangeLang(lng)
    }

    function logout() {
        autoRetry(t('login.contextLogout'), () => window.service.userLogout(), 5).then(response => {
            if (response.data.success) {
                console.log("Logout success. Going on homepage")
                props.onLogout()
                navigate('/')
            }
        })
    }

    return (
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container-fluid">
                <NavLink to="/" className="navbar-brand"><img src={logo} alt="Logo"/></NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link">{t('menu.home')}</NavLink>
                        </li>
                        {userEntitlements.isAdmin && <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle"
                               href="#"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false">{t('menu.user')}</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <NavLink to="/user"
                                             className="dropdown-item">{t('menu.personal')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/business"
                                             className="dropdown-item">{t('menu.business')}</NavLink>
                                </li>
                            </ul>
                        </li>}
                        {(userEntitlements.isLoggedIn && !userEntitlements.isAdmin) && <li className="nav-item">
                            <NavLink to="/business" className="nav-link">{t('menu.business')}</NavLink>
                        </li>}
                        {userEntitlements.entitlements?.canAccessProject && !userEntitlements.entitlements?.canListBilling &&
                            <li className="nav-item">
                                <NavLink to="/project" className="nav-link">{t('menu.project')}</NavLink>
                            </li>}
                        {userEntitlements.entitlements?.canListBilling && <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle"
                               href="#"
                               role="button"
                               data-bs-toggle="dropdown"
                               aria-expanded="false">{t('menu.project')}</a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li>
                                    <NavLink to="/project" className="dropdown-item">{t('menu.wallet')}</NavLink>
                                </li>
                                <li>
                                    <hr className="dropdown-divider"/>
                                </li>
                                <li>
                                    <NavLink to="/billing/projectBilling"
                                             className="dropdown-item">{t('menu.projectBilling')}</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/billing/bill"
                                             className="dropdown-item">{t('menu.bill')}</NavLink>
                                </li>
                            </ul>
                        </li>}
                        <li className="nav-item">
                            <NavLink to="/register" className="nav-link">{t('menu.register')}</NavLink>
                        </li>
                        {userEntitlements.isLoggedIn && <li className="nav-item">
                            <NavLink to="/request"
                                     className="nav-link">{t('menu.request')} ({userEntitlements.myRequestsCount}/{userEntitlements.requestsToApproveCount})</NavLink>
                        </li>}
                    </ul>

                    {userEntitlements.isAdmin && <span className="badge bg-success">{t('user.admin')}</span>}
                    {userEntitlements.inspectorProfile &&
                        <span className="badge bg-success">
                            <NavLink to="/user/inspectorProfile"
                                     className="nav-link">
                                {t('user.inspector')}
                               </NavLink></span>}
                    {userEntitlements.userEmail &&
                        <span className="badge bg-light text-dark">
                            {userEntitlements.userEmail}<br/>
                            {userEntitlements.inspectorProfile &&
                                <NavLink to="/user/inspectorProfile"
                                         className="nav-link">
                                    {userEntitlements.inspectorProfile?.fullName}
                                </NavLink>

                            }
                        </span>
                    }

                    {userEntitlements.isLoggedIn && <button className="btn btn-outline-primary"
                                                            onClick={() => logout()}>{t('menu.logout')}</button>}
                    {!userEntitlements.isLoggedIn && <NavLink className="btn btn-outline-primary"
                                                              to="/login">{t('menu.login')}</NavLink>}

                    {toLangOnly(i18n.language) === 'en' && <button className="btn btn-outline-primary"
                                                                   onClick={() => changeLanguage('fr')}> Français</button>}
                    {toLangOnly(i18n.language) !== 'en' && <button className="btn btn-outline-primary"
                                                                   onClick={() => changeLanguage('en')}> English</button>}
                </div>
            </div>
        </nav>
    )
}

export default Header;
